import React from 'react';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import Header2 from '../components/layout/Header2';
import { Link } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import { window } from 'browser-monads';

const NotFound = () => {
  const language = window.location.pathname.substring(0, 3);
  const intl = useIntl();
  const notFoundTitle = intl.formatMessage({ id: 'notfound.header' });
  const notFoundDescription = intl.formatMessage({ id: 'notfound.description' });
  const goBack = intl.formatMessage({ id: 'goback' });
  return (
    <Layout>
      <Header2 />
      <div className='mentions'>
        <LabelText className='mb-8 text-gray-600 text-3xl text-center'>{notFoundTitle}</LabelText>
        <p className='text-center'>{notFoundDescription}</p>
      </div>
      <div className='goBack'>
        <Link className='mb-8 text-gray-600 text-3xl text-center' to={language + '/'}>
          {goBack}
        </Link>
      </div>
    </Layout>
  );
};

export default NotFound;
