import React from 'react';
import { Link } from 'gatsby';
import LogoIcon from '../../svg/LogoIcon';
import { window } from 'browser-monads';
import { Helmet } from 'react-helmet';
import { useIntl } from 'gatsby-plugin-intl';
import CookieConsent, { Cookies } from 'react-cookie-consent';
// import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';

const Header2 = () => {
  const language = window.location.pathname.substring(3, 14);
  const languageAccueil = window.location.pathname.substring(0, 3);
  // console.log(language);
  const intl = useIntl();
  const messageBanner = intl.formatMessage({ id: 'banner.message' });
  const messageBannerPolicy = intl.formatMessage({ id: 'banner.policy' });
  const messageBannerMore = intl.formatMessage({ id: 'banner.endmessage' });
  const declineButton = intl.formatMessage({ id: 'banner.decline' });
  const acceptButton = intl.formatMessage({ id: 'banner.accept' });

  return (
    <header className='sticky top-0 bg-white shadow'>
      <Helmet>
        <html lang='fr' />
        <title>Artngreen | Jean-Emmanuel Dupont</title>
        <meta name='description' content='Jardinier Paysagiste à Fayence, Entretien et création de jardins' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta name='google-site-verification' content='H6KMeBBgIEKYKuxU9781A_qp0OKJ7aeJKTXdHqs1fbw' />
        <meta name='robots' content='index, follow' />
      </Helmet>
      {/* <div className='flexheader container mx-auto py-4 px-8'> */}
      <div className='flexheader container flex sm:flex-row justify-between items-center mx-auto py-2 newpx-8'>
        <div className='flex-header2 items-center text-2xl'>
          <div className='w-16 mr-3'>
            <LogoIcon />
          </div>
          <Link to={languageAccueil + '/'}><span className='logoname'>Art'n'Green</span></Link>
        </div>
        <div className='flag-container2'>
          <Link className='flagFr' to={'/fr' + language} />
          <Link className='flagEn' to={'/en' + language} />
        </div>
      </div>
      <CookieConsent
        // debug
        location='bottom'
        style={{ background: '#705d3b' }}
        buttonStyle={{ background: '#fff', fontSize: '13px' }}
        buttonText={acceptButton}
        declineButtonStyle={{ background: 'rgb(59, 50, 40)', fontSize: '14px' }}
        declineButtonText={declineButton}
        expires={365}
        sameSite='lax'
        onAccept={() => {
          // initializeAndTrack(useLocation);
          Cookies.set('gatsby-gdpr-google-analytics', 'true', { expires: 365 });
        }}
        enableDeclineButton
        setDeclineCookie
        cookieName='gatsby-gdpr-google-analytics'
      >
        {messageBanner} {' '}
        <Link to='/privacy'>{messageBannerPolicy}</Link>{messageBannerMore}.
      </CookieConsent>
    </header>
  );
};
export default Header2;
